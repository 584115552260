import { ComponentType } from 'react';

import { RoutesPath } from '@/constants';
import {
  EmployeesPage,
  LoginPage,
  NoAccessPage,
  OAuthCallbackPage,
  ProfilePage,
  ProjectsPage,
  TechWorkPage,
  TestEditPage,
  TestsPage,
} from '@/pages';

import { TestStatsPage } from '../pages/TestStatsPage';

type RouteType = {
  component: ComponentType;
  isAdminAccept?: boolean;
  isPublic?: boolean;
  path: string;
  props?: Record<string, any>;
};

export const routes: Array<RouteType> = [
  {
    component: LoginPage,
    isPublic: true,
    path: RoutesPath.Login,
  },
  {
    component: OAuthCallbackPage,
    isPublic: true,
    path: RoutesPath.OAuthCallback,
  },
  {
    component: TestsPage,
    path: RoutesPath.Tests,
  },
  {
    component: TestEditPage,
    path: RoutesPath.TestEdit,
  },
  {
    component: TestStatsPage,
    path: RoutesPath.TestStatsPage,
  },
  {
    component: EmployeesPage,
    isAdminAccept: true,
    path: RoutesPath.Employees,
  },
  {
    component: ProjectsPage,
    path: RoutesPath.Projects,
  },
  {
    component: NoAccessPage,
    path: RoutesPath.NoAccess,
  },
  {
    component: ProfilePage,
    path: RoutesPath.Profile,
  },
];

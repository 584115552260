import { useSortable } from '@dnd-kit/sortable';
import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { Delete, Drag } from '@/assets';
import { CrosswordAnswer } from '@/containers/Blocks/CrosswordQuestionBlock/CrosswordAnswer';
import { CrosswordQuestion } from '@/containers/Blocks/CrosswordQuestionBlock/CrosswordQuestion';
import { useDeleteQuestionMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  canDelete: boolean;
  id: number;
  questionIndex: number;
  questionsNumber: number;
  text: string;
};

export const CrosswordQuestionBlock: React.FC<Props> = ({
  canDelete,
  id,
  questionIndex,
  questionsNumber,
  text,
}) => {
  const { attributes, listeners } = useSortable({
    id,
  });
  const [deleteQuestion] = useDeleteQuestionMutation();

  const handleDeleteQuestionClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    deleteQuestion(id);
  };

  return (
    <div className={styles.crosswordWrapper} key={id}>
      <Row className={styles.buttonsWrapper}>
        <Col className={styles.questionCount}>
          <Row>
            <Typography.Text className={styles.currentCount}>{questionIndex}&nbsp;</Typography.Text>
            <Typography.Text className={styles.totalCount}>/ {questionsNumber}</Typography.Text>
          </Row>
        </Col>
        <Col
          className={classNames(styles.delete, !canDelete && styles.disabled)}
          onClick={handleDeleteQuestionClick}
        >
          <Delete />
        </Col>
      </Row>
      <CrosswordQuestion id={id} text={text} />
      <CrosswordAnswer questionId={id} />
    </div>
  );
};

import { PermissionAction, PermissionResource } from '@tests/types';
import { Dropdown, MenuProps } from 'antd';
import { Link } from 'react-router-dom';

import { AllInteract, DropdownArrow, Logout, Projects, User, Users } from '@/assets';
import { DropdownItem } from '@/atoms';
import { usePermissions } from '@/hooks';
import { useGetCurrentUserQuery, useLogoutUserMutation } from '@/services';

import styles from './styles.module.scss';

export const UserDropdown = () => {
  const { data: user } = useGetCurrentUserQuery();
  const [logoutUser] = useLogoutUserMutation();
  const avatar = user?.avatar?.url;

  const checkPermissions = usePermissions;

  const generalItems: MenuProps['items'] = [
    {
      key: 'Profile',
      label: (
        <Link to="/profile">
          <DropdownItem icon={<User />} label="Профиль" />
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'Logout',
      label: (
        <DropdownItem icon={<Logout />} label="Выйти из аккаунта" onClick={() => logoutUser()} />
      ),
    },
  ];

  const customizableItems: MenuProps['items'] = [
    {
      key: 'Test',
      label: (
        <Link to="/tests">
          <DropdownItem icon={<AllInteract />} label="Все интерактивы" />
        </Link>
      ),
    },
    {
      key: 'Project',
      label: (
        <Link to="/projects">
          <DropdownItem icon={<Projects />} label="Проекты" />
        </Link>
      ),
    },
    {
      key: 'Employee',
      label: (
        <Link to="/employees">
          <DropdownItem icon={<Users />} label="Сотрудники" />
        </Link>
      ),
    },
  ];

  const permittedItems: MenuProps['items'] = customizableItems.filter((item) =>
    checkPermissions(item.key as PermissionResource, PermissionAction.Read),
  );

  return (
    <Dropdown
      rootClassName={styles.dropDownMenu}
      menu={{ items: [...permittedItems, ...generalItems] }}
      trigger={['click']}
      destroyPopupOnHide
    >
      <div className={styles.dropdownContent}>
        <div className={styles.avatarWrapper}>
          {avatar ? <img src={avatar} alt={user?.name} /> : <User className={styles.avatar} />}
        </div>
        <div className={styles.name}>{user?.name}</div>
        <DropdownArrow className={styles.arrow} />
      </div>
    </Dropdown>
  );
};

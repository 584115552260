import { TestType, TestValidationError, TestValidationErrorTarget } from '@tests/types';

import { message } from '@/atoms';

import { getValidationErrorsByTargetAndField } from './validationErrors';

export const getCommonNotification = () => {
  message({
    description: 'Заполните обязательные поля',
    title: 'Не удалось применить изменения',
    type: 'error',
  });
};

// TODO: Переписать на универсальный нотификейшн
export const getValidationErrorsNotifications = (errors: TestValidationError[], type: TestType) => {
  if (type === TestType.Crossword) {
    const validationErrorsByCrosswordField = getValidationErrorsByTargetAndField(
      errors,
      TestValidationErrorTarget.Test,
      type,
    );
    if (validationErrorsByCrosswordField.length > 0) {
      validationErrorsByCrosswordField.forEach((error) => {
        message({
          description: error.message,
          title: 'Не удалось применить изменения',
          type: 'error',
        });
      });
    } else {
      getCommonNotification();
    }
  } else {
    getCommonNotification();
  }
};

import { CloseOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { ArrowDown } from '@/assets';

import styles from './styles.module.scss';

type Props = {
  after?: string;
  classname?: string;
  disableItem?: number;
  loading?: boolean;
  onChange?: (value: any) => void;
  options: { label: string; tag: ReactNode; value: string | number }[];
  value?: any;
} & SelectProps;

export const TagSelect: React.FC<Props> = ({
  after,
  classname,
  disableItem,
  loading,
  onChange,
  options,
  value,
  ...antProps
}) => (
  <div className={styles.wrapper}>
    <div className={styles.prefix}>{after}</div>
    <Select
      size="large"
      popupClassName={styles.selectorOptions}
      dropdownStyle={{ backgroundColor: 'var(--color-grey-lvl2)' }}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onClear={() => onChange(null)}
      value={value}
      onChange={onChange}
      suffixIcon={<ArrowDown className={styles.icon} />}
      clearIcon={<CloseOutlined />}
      className={classNames(styles.selector, styles[classname])}
      placeholder="Выбрать"
      defaultValue={null}
      {...antProps}
    >
      <Select.Option key="null" value={null}>
        Не выбрано
      </Select.Option>
      {options?.map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          disabled={disableItem === option.value}
        >
          {option.tag}
          {option.label}
        </Select.Option>
      ))}
    </Select>
  </div>
);

import {
  EmployeeAuthPasswordResponsePrivate,
  UpdateEmployeeAuthPasswordBodyPrivate,
} from '@tests/types';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import { equals } from 'ramda';
import React, { useState } from 'react';

import { Button, FormItem } from '@/atoms';
import { DrawerWrapper, UserBasicInfo } from '@/components';
import {
  useGetCurrentUserQuery,
  useUpdateUserLoginPasswordMutation,
  useUseUpdateUserNameAvatarMutation,
} from '@/services';
import { ButtonType } from '@/types';

import styles from './styles.module.scss';

type Props = {
  authData?: EmployeeAuthPasswordResponsePrivate;
  onClose: () => void;
};

export const ProfileEditor: React.FC<Props> = ({ authData, onClose }) => {
  const [form] = useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  const { data: user } = useGetCurrentUserQuery();

  const { getFieldsValue } = form;

  const initialValues = user
    ? {
        avatar: user.avatar,
        login: authData?.login || '',
        name: user.name,
      }
    : {};

  const [updateAuthPassword] = useUpdateUserLoginPasswordMutation();
  const [updateNameAvatar] = useUseUpdateUserNameAvatarMutation();

  const handleValuesChange = () => {
    const currentValues = form.getFieldsValue();
    const isValuesEqual = equals(currentValues, initialValues);
    setIsDisabled(isValuesEqual);
  };

  const handleSaveEmployee = async () => {
    const formValues = getFieldsValue();

    if (authData) {
      const updateAuthData: UpdateEmployeeAuthPasswordBodyPrivate = {
        login: formValues?.login,
        password: authData.password,
      };

      await updateAuthPassword(updateAuthData);
    }

    await updateNameAvatar({
      avatarId: formValues.avatar?.id || null,
      name: formValues.name,
    });

    onClose();
  };

  return (
    <DrawerWrapper
      title="Редактирование данных"
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      modalClassName={styles.editorWrapper}
      footer={
        <div className={styles.editBtnWrapper}>
          <Button
            flex
            type={ButtonType.Secondary}
            className={classNames(styles.editBtn, styles.editCloseBtn)}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            flex
            className={classNames(styles.editBtn, styles.editSaveBtn)}
            onClick={() => form.submit()}
            type={ButtonType.Primary}
            disabled={isDisabled}
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles.form}
        onFinish={handleSaveEmployee}
        initialValues={initialValues}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={[15, 15]}>
          <UserBasicInfo dropZoneRootClass={styles.basicInfo} />
          <Col span={24}>
            <FormItem
              flex
              label="Логин"
              name="login"
              className={styles.label}
              rules={[
                {
                  message: 'Пожалуйста, введите логин',
                  required: true,
                },
                {
                  max: 32,
                  message: 'Логин слишком длинный: используйте не более 32 символов',
                },
              ]}
            >
              <Input className={styles.input} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </DrawerWrapper>
  );
};

import { Avatar, Col, Divider, Row, Typography } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

import { Edit, ThinUser as User } from '@/assets';
import { Button } from '@/atoms';
import { CreateDropdown, PasswordEditor, ProfileEditor, TestsListHeader } from '@/containers';
import { useGetCurrentUserLoginPasswordQuery, useGetCurrentUserQuery } from '@/services';

import styles from './styles.module.scss';

export const ProfilePage = () => {
  const [showProfileEditor, setShowProfileEditor] = useState(false);
  const [showPasswordEditor, setShowPasswordEditor] = useState(false);
  const { data: user } = useGetCurrentUserQuery();
  const { data: authData } = useGetCurrentUserLoginPasswordQuery();

  const handleToggleProfileEditor = () => setShowProfileEditor((prevState) => !prevState);

  const handleTogglePasswordEditor = () => setShowPasswordEditor((prevState) => !prevState);

  // TODO, нужно перерисовать и переделать, потому что:
  // 1. Форма редактирования данных пользователя отправляет два разных запроса
  // 2. Форма редактирования пароля отправляет логин в запрос, хотя поля логина нет на форме

  return (
    <>
      <TestsListHeader hideSearch button={<CreateDropdown />} />

      <Col className={styles.wrapper}>
        <div className={styles.title}>
          <Typography.Title>Профиль</Typography.Title>
        </div>
        <div className={styles.settingsWrapper}>
          <Typography.Text type="secondary" className={styles.settingsTitle}>
            Личные данные
          </Typography.Text>
          <Divider />
          <div className={styles.settingsBody}>
            <Avatar src={user?.avatar?.url} className={classNames(styles.avatarWrapper)}>
              {!user?.avatar && <User className={styles.icon} />}
            </Avatar>
            <Row align="middle" className={styles.nameEdit}>
              <Typography.Text>{user?.name}</Typography.Text>
              <Button
                className={classNames(styles.button, styles.editBtn)}
                onClick={handleToggleProfileEditor}
              >
                <Edit width={15} height={15} />
              </Button>
            </Row>
            <div className={styles.login}>
              <Typography.Text type="secondary">{authData?.login}</Typography.Text>
            </div>
          </div>
        </div>
        <Button className={styles.button} htmlType="reset" onClick={handleTogglePasswordEditor}>
          Обновить пароль
        </Button>
      </Col>
      {showProfileEditor && (
        <ProfileEditor authData={authData} onClose={handleToggleProfileEditor} />
      )}
      {showPasswordEditor && (
        <PasswordEditor authData={authData} onClose={handleTogglePasswordEditor} />
      )}
    </>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import {
  type EmployeeAuthPasswordResponsePrivate,
  type GetMeResponsePrivate,
  type GetTokensResponsePrivate,
  type LoginBodyPrivate,
  type UpdateEmployeeAuthPasswordBodyPrivate,
  UpdateEmployeeNameAndAvatarBodyPrivate,
} from '@tests/types';

import { ApiPath, Provider } from '@/constants';
import { createQuery, createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const usersApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getCurrentUser: build.query<GetMeResponsePrivate, void>({
      providesTags: () => [{ type: 'Users' }],
      query: createQuery(ApiPath.Auth, ApiPath.Me),
    }),
    getCurrentUserLoginPassword: build.query<EmployeeAuthPasswordResponsePrivate, void>({
      providesTags: () => [{ type: 'Users' }],
      query: () => ({
        method: 'GET',
        url: createUrl(ApiPath.Auth, ApiPath.Me, ApiPath.Password),
      }),
    }),
    loginUser: build.mutation<GetTokensResponsePrivate, LoginBodyPrivate>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Login),
      }),
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Logout),
      }),
    }),
    oAuthCallback: build.mutation<
      { accessToken: string },
      { code: string; provider: Provider; redirectUri: string }
    >({
      query: ({ provider, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
          redirect_uri: params?.redirectUri,
        },
        url: createUrl(ApiPath.Auth, provider, ApiPath.Callback),
      }),
    }),
    updateUserLoginPassword: build.mutation<
      EmployeeAuthPasswordResponsePrivate,
      UpdateEmployeeAuthPasswordBodyPrivate
    >({
      invalidatesTags: () => [{ type: 'Users' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Me, ApiPath.Password),
      }),
    }),
    useUpdateUserNameAvatar: build.mutation<
      GetMeResponsePrivate,
      UpdateEmployeeNameAndAvatarBodyPrivate
    >({
      invalidatesTags: () => [{ type: 'Users' }],
      query: (data) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Auth, ApiPath.Me),
      }),
    }),
  }),
  reducerPath: 'usersApi',
  tagTypes: ['Users', 'Employees'],
});

export const {
  useGetCurrentUserLoginPasswordQuery,
  useGetCurrentUserQuery,
  useLoginUserMutation,
  useLogoutUserMutation,
  useOAuthCallbackMutation,
  useUpdateUserLoginPasswordMutation,
  useUseUpdateUserNameAvatarMutation,
} = usersApi;

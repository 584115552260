import { Col, Form, Input } from 'antd';
import classNames from 'classnames';

import { DropZone, FormItem } from '@/atoms';
import { DropType, DropZoneType } from '@/constants';

import styles from './styles.module.scss';

export type UserBasicInfoProps = {
  dropZoneRootClass?: string;
};

export const UserBasicInfo: React.FC<UserBasicInfoProps> = ({ dropZoneRootClass }) => (
  <>
    <Col span={24}>
      <Form.Item noStyle name="avatar">
        <DropZone
          dropType={DropType.Image}
          type={DropZoneType.Avatar}
          rootClassName={classNames(styles.dropZoneRoot, dropZoneRootClass)}
          className={styles.dropZone}
        />
      </Form.Item>
    </Col>
    <Col span={24}>
      <FormItem flex label="ФИО" name="name" className={styles.label}>
        <Input className={styles.input} placeholder="Введите имя сотрудника" />
      </FormItem>
    </Col>
  </>
);

import { Input } from 'antd';
import { Rule } from 'antd/es/form';
import React from 'react';

import { Eye, EyeInvisible } from '@/assets';
import { FormItem } from '@/atoms';
import { useGetCurrentUserLoginPasswordQuery } from '@/services';

import styles from './styles.module.scss';

type PasswordInputProps = {
  dependencies?: string[];
  label: string;
  name: 'password' | 'confirm';
  placeholder?: string;
};

const EyeIcon = (visible: boolean) =>
  visible ? <Eye className={styles.eyeIcon} /> : <EyeInvisible className={styles.eyeIcon} />;

export const PasswordInput: React.FC<PasswordInputProps> = ({
  dependencies = [],
  label,
  name,
  placeholder,
}) => {
  const { data: { login } = {} } = useGetCurrentUserLoginPasswordQuery();
  const passwordRules: Rule[] = [
    { message: 'Пожалуйста, введите новый пароль', required: true },
    { message: 'Пароль слишком короткий: минимум 6 символов', min: 6 },
    { max: 32, message: 'Пароль слишком длинный: максимум 32 символа' },
    {
      message: 'Пароль содержит запрещённые символы',
      pattern: /^[0-9A-Za-z`!@#$%^&*()_=+\[\]{};:"\\|,]{1,}$/gm,
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (value?.length > 0 && login === value) {
          throw new Error('Пароль не должен совпадать с логином');
        }
      },
    }),
  ];

  const confirmRules: Rule[] = [
    { message: 'Пожалуйста, подтвердите новый пароль', required: true },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        throw new Error('Пароли не совпадают');
      },
    }),
  ];

  return (
    <FormItem
      flex
      label={label}
      name={name}
      dependencies={dependencies}
      className={styles.label}
      rules={name === 'password' ? passwordRules : confirmRules}
    >
      <Input.Password
        className={styles.input}
        rootClassName={styles.inputRoot}
        placeholder={placeholder || 'Введите пароль'}
        iconRender={EyeIcon}
      />
    </FormItem>
  );
};

import {
  EmployeeResponsePrivate,
  PermissionAction,
  PermissionResource,
  UpdateEmployeeAuthPasswordBodyPrivate,
} from '@tests/types';
import { Col, Form, Input, Row } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';

import { Button, FormItem, PasswordInput } from '@/atoms';
import { DrawerWrapper } from '@/components';
import styles from '@/containers/EmployeeEditor/styles.module.scss';
import { usePermissions } from '@/hooks';
import {
  useGetEmployeeAuthPasswordByIdQuery,
  useUpdateEmployeeAuthPasswordMutation,
} from '@/services';
import { ButtonType } from '@/types';

type Props = {
  employee: EmployeeResponsePrivate<string>;
  onClose: () => void;
};

export const EmployeePasswordEditor: React.FC<Props> = ({ employee, onClose }) => {
  const { data: authData } = useGetEmployeeAuthPasswordByIdQuery(
    { id: employee?.id },
    { skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read) || !employee?.id },
  );

  const [updateAuthPassword] = useUpdateEmployeeAuthPasswordMutation();

  const [form] = Form.useForm();

  const { getFieldsValue } = form;

  useEffect(() => {
    if (authData?.login) {
      form.setFieldValue('login', authData.login);
    }
  }, [authData, form]);

  const onFinish = async () => {
    const formValues = getFieldsValue();

    const updateData: UpdateEmployeeAuthPasswordBodyPrivate & { id: number } = {
      id: employee.id,
      login: formValues?.login,
      password: formValues?.password,
    };

    // if (formValues.login) updateData.login = formValues.login;
    // if (formValues.password) updateData.password = formValues.password;

    await updateAuthPassword(updateData);

    onClose();
  };

  return (
    <DrawerWrapper
      title="Логин и пароль"
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      modalClassName={styles.editorWrapper}
      footer={
        <div className={styles.editBtnWrapper}>
          <Button
            flex
            type={ButtonType.Secondary}
            className={classNames(styles.editBtn, styles.editCloseBtn)}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            flex
            className={classNames(styles.editBtn, styles.editSaveBtn)}
            onClick={() => form.submit()}
            type={ButtonType.Primary}
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Form form={form} className={styles.form} onFinish={onFinish}>
        <Row gutter={[15, 15]} className={styles.inputWrapper}>
          <Col span={24}>
            <FormItem
              flex
              label="Логин"
              name="login"
              className={styles.label}
              rules={[
                {
                  message: 'Пожалуйста, введите логин',
                  required: true,
                },
                {
                  max: 32,
                  message: 'Логин слишком длинный: используйте не более 32 символов',
                },
              ]}
            >
              <Input className={styles.input} placeholder="Введите логин" />
            </FormItem>
          </Col>
          <Col span={24}>
            <PasswordInput
              name="password"
              label="Новый пароль"
              placeholder="Введите новый пароль"
            />
          </Col>
          <Col span={24}>
            <PasswordInput
              name="confirm"
              label="Повторите пароль"
              placeholder="Повторите новый пароль"
              dependencies={['password']}
            />
          </Col>
        </Row>
      </Form>
    </DrawerWrapper>
  );
};

import { ExclamationCircleFilled } from '@ant-design/icons';
import { FileResponsePrivate } from '@tests/types';
import { Tooltip, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { UploadFile } from 'antd/lib';
import classNames from 'classnames';
import { always, cond, equals, multiply, T } from 'ramda';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Trash } from '@/assets';
import { DropType, DropZoneType, SECOND } from '@/constants';
import { ConfigContext } from '@/providers';
import {
  getAcceptString,
  getAcceptStringPlaceholder,
  getMaxSize,
  isComplete,
  isConverting,
  isError,
  isInProgress,
} from '@/utils';

import { useCreateUploadFileMutation, useGetFileByIdMutation } from '../../services';
import { ButtonSize, ButtonType } from '../../types';
import { isSuccessResult } from '../../utils';
import { Button } from '../Button';
import { DropZoneFilePreview } from '../DropZoneFilePreview';
import {
  DropZonePreviewAvatar,
  DropZonePreviewCompact,
  DropZonePreviewRegular,
} from '../DropZonePreview';
import { message } from '../Message';
import { UPLOAD_MAX_TRIES, UPLOAD_TIMEOUT } from './const';
import styles from './styles.module.scss';

const { Dragger } = Upload;

interface Props {
  backgroundSize?: string;
  className?: string;
  dropType: DropType;
  dropzonePreviewClassname?: string;
  onChange?: (file?: FileResponsePrivate) => void;
  placeholder?: string;
  rootClassName?: string;
  type?: DropZoneType;
  validErrorMessage?: string;
  value?: FileResponsePrivate;
}

const getDropZonePreviewComponent = cond([
  [equals(DropZoneType.Compact), always(DropZonePreviewCompact)],
  [equals(DropZoneType.Avatar), always(DropZonePreviewAvatar)],
  [T, always(DropZonePreviewRegular)],
]);

export const DropZone = ({
  onChange,
  type = DropZoneType.Regular,
  value,
  dropType,
  rootClassName,
  dropzonePreviewClassname,
  validErrorMessage,
  className,
  placeholder = 'Добавьте изображение',
  backgroundSize,
}: Props) => {
  const {
    upload: { maxSizeImage, timeFileCheck },
  } = useContext(ConfigContext);
  const defaultCheckStatus = multiply(timeFileCheck, SECOND);

  const [createUploadFile] = useCreateUploadFileMutation();
  const [getFileById] = useGetFileByIdMutation();

  const maxSizeFile = getMaxSize(dropType) * maxSizeImage;
  const accept = useMemo(() => getAcceptString(dropType), [dropType]);
  const acceptPlaceholder = useMemo(() => getAcceptStringPlaceholder(dropType), [dropType]);

  const [file, setFile] = useState(value);
  const [progress, setProgress] = useState(0);
  const [uploadState, setUploadState] = useState<UploadFileStatus | 'converting' | null>(null);

  const DropZonePreview = getDropZonePreviewComponent(type);

  const handleDelete = () => {
    onChange(null);
    setFile(null);
  };

  useEffect(() => {
    setFile(value);
  }, [value]);

  const handleChange: (info: UploadChangeParam<UploadFile<FileResponsePrivate>>) => void = (
    info,
  ) => {
    const { response, status } = info.file;
    setUploadState(status);
    if (status === 'done') {
      onChange(response);
      setFile(response);
      setUploadState('converting');
    }
  };

  const uploadImage = async (options: any) => {
    const { file, onError, onProgress, onSuccess } = options;
    try {
      const { size: contentLength, type: contentType } = file;

      const forbiddenTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/*'];

      if (forbiddenTypes.some((forbidden) => contentType.startsWith(forbidden))) {
        throw new Error('Недопустимый формат файла');
      }

      if (contentLength > maxSizeFile) {
        throw new Error('Размер файла превышает допустимый размер');
      }

      const createUploadResult = await createUploadFile({
        contentLength,
        contentType,
      });

      if (!isSuccessResult(createUploadResult)) {
        throw createUploadResult.error;
      }

      const {
        data: { fileId, uploadUrl },
      } = createUploadResult;

      let tries = 0;
      let uploadSuccess;

      setProgress(0);
      onProgress(0);
      do {
        tries += 1;

        console.log('Upload file', { UPLOAD_MAX_TRIES, UPLOAD_TIMEOUT, tries });

        let response;
        try {
          response = await fetch(uploadUrl, {
            body: file,
            credentials: 'omit',
            headers: {
              'Content-Type': contentType,
              'x-amz-acl': 'public-read',
            },
            method: 'PUT',
          });

          uploadSuccess = response.ok;
        } catch (error) {
          console.log('Upload error', { error });
          uploadSuccess = false;
        }

        if (!uploadSuccess && tries < UPLOAD_MAX_TRIES) {
          const wait = UPLOAD_TIMEOUT * tries;

          console.log('Wait', wait);
          await new Promise((resolve) => setTimeout(resolve, wait));
        }
      } while (!uploadSuccess && tries < UPLOAD_MAX_TRIES);

      setProgress(100);
      onProgress(100);

      if (!uploadSuccess) {
        throw new Error('Не удалось загрузить файл');
      }

      const fileResponse = await getFileById(fileId);

      if (!isSuccessResult(fileResponse)) {
        throw fileResponse.error;
      }
      onSuccess(fileResponse?.data);
    } catch (err) {
      message({
        description: typeof err?.message === 'string' ? err?.message : JSON.stringify(err?.message),
        title: `Ошибка загрузки ${err?.code || ''}`,
        type: 'error',
      });
      onError({ err });
      setUploadState(null);
      setProgress(0);
      setFile(null);
    }
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isInProgress(file) || isConverting(file)) {
      timerId = setTimeout(async function update() {
        const checkedFile = await getFileById(file.id);
        if ('data' in checkedFile) {
          if (isInProgress(checkedFile?.data) || isConverting(checkedFile?.data)) {
            timerId = setTimeout(update, defaultCheckStatus);
            if (isConverting(checkedFile?.data) && checkedFile?.data?.variants.length > 0) {
              setFile(checkedFile?.data?.variants[0]);
              onChange(checkedFile?.data?.variants[0]);
            }
          } else if (isComplete(checkedFile?.data)) {
            setFile(checkedFile?.data);
            onChange(checkedFile?.data);
          }
        }
      }, defaultCheckStatus);
    } else {
      setFile(file);
      setUploadState(null);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [file, file?.status, file?.id]);

  if (file && (isComplete(file) || isError(file))) {
    return (
      <div className={rootClassName}>
        <div className={classNames(styles.previewWrapper, className)}>
          <DropZoneFilePreview
            file={file}
            backgroundSize={backgroundSize}
            rootClassName={classNames(
              dropzonePreviewClassname,
              type === DropZoneType.Avatar && styles.avatarPreview,
            )}
          />
          <Button
            type={ButtonType.Secondary}
            onClick={handleDelete}
            size={ButtonSize.Small}
            square
            className={classNames(
              styles.deleteButton,
              type === DropZoneType.Avatar && styles.avatarDeleteButton,
            )}
            beforeIcon={<Trash />}
          />
          {isError(file) && (
            <Tooltip
              title={
                <>
                  <span>Изображение загружено с ошибкой.</span>
                  <br />
                  <span>Проверьте превью интерактива, при необходимости повторите загрузку</span>
                </>
              }
              className={classNames(
                styles.errorSign,
                type === DropZoneType.Avatar && styles.avatarErrorSign,
              )}
            >
              <ExclamationCircleFilled />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      <Dragger
        rootClassName={classNames(
          styles.dropZoneRoot,
          className,
          validErrorMessage && styles.error,
          type === DropZoneType.Avatar && styles.avatar,
        )}
        customRequest={uploadImage}
        onChange={handleChange}
        withCredentials
        accept={accept}
        showUploadList={false}
      >
        <DropZonePreview
          accept={acceptPlaceholder}
          title={placeholder}
          uploadState={uploadState}
          progress={progress}
        />
      </Dragger>
    </div>
  );
};

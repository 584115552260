import {
  EmployeeAuthPasswordResponsePrivate,
  UpdateEmployeeAuthPasswordBodyPrivate,
} from '@tests/types';
import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import { all, complement, isEmpty, values } from 'ramda';
import React, { useState } from 'react';

import { Button, PasswordInput } from '@/atoms';
import { DrawerWrapper } from '@/components';
import { useUpdateUserLoginPasswordMutation } from '@/services';
import { ButtonType } from '@/types';

import styles from './styles.module.scss';

type Props = {
  authData?: EmployeeAuthPasswordResponsePrivate;
  onClose: () => void;
};

export const PasswordEditor: React.FC<Props> = ({ authData, onClose }) => {
  const [form] = useForm();
  const [isDisabled, setIsDisabled] = useState(true);

  const [updateAuthPassword] = useUpdateUserLoginPasswordMutation();

  const handleValuesChange = () => {
    const bothTouched = form.isFieldsTouched(['password', 'confirm'], true);
    const bothFilled = all(complement(isEmpty), values(form.getFieldsValue()));
    setIsDisabled(!bothTouched || !bothFilled);
  };

  const handleSavePassword = async () => {
    const fieldsValues = form.getFieldsValue();

    if (authData) {
      const updateAuthData: UpdateEmployeeAuthPasswordBodyPrivate = {
        login: authData.login,
        password: fieldsValues.password,
      };

      await updateAuthPassword(updateAuthData);
    }
    onClose();
  };

  return (
    <DrawerWrapper
      title="Обновление пароля"
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      modalClassName={styles.editorWrapper}
      footer={
        <div className={styles.editBtnWrapper}>
          <Button
            flex
            type={ButtonType.Secondary}
            className={classNames(styles.editBtn, styles.editCloseBtn)}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            flex
            className={classNames(styles.editBtn, styles.editSaveBtn)}
            onClick={() => form.submit()}
            type={ButtonType.Primary}
            disabled={isDisabled}
          >
            Обновить
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        className={styles.form}
        onFinish={handleSavePassword}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <PasswordInput
              name="password"
              label="Новый пароль"
              placeholder="Введите новый пароль"
            />
          </Col>
          <Col span={24}>
            <PasswordInput
              name="confirm"
              label="Повторите новый пароль"
              placeholder="Повторите пароль"
              dependencies={['password']}
            />
          </Col>
        </Row>
      </Form>
    </DrawerWrapper>
  );
};
